import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { AlertTriangle, RefreshCcw, Calendar, Server, Coins } from 'lucide-react';
import { analyticsService } from '../services/analytics';
import PriceChart from '../components/analytics/PriceChart';
import PriceStats from '../components/analytics/PriceStats';
import { format } from 'date-fns';

const coinAmountOptions = [
  { value: 100000, label: '100K' },
  { value: 500000, label: '500K' },
  { value: 1000000, label: '1M' },
  { value: 5000000, label: '5M' }
];

const Analytics = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [timeframe, setTimeframe] = useState('all');
  const [serverStatus, setServerStatus] = useState('checking');
  const [selectedCoins, setSelectedCoins] = useState(5000000);

  // Generate dynamic timeframe options
  const timeframeOptions = useMemo(() => {
    const today = new Date();
    const formattedToday = format(today, 'MMM d');
    
    return [
      { value: 'all', label: `All Time (Dec 14-${formattedToday})` },
      { value: '7d', label: 'Last 7 Days' },
      { value: '3d', label: 'Last 3 Days' }
    ];
  }, []);

  const updateServerStatus = useCallback(async () => {
    const isHealthy = await analyticsService.checkHealth();
    setServerStatus(isHealthy ? 'online' : 'offline');
    return isHealthy;
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      // Check server health first
      const isHealthy = await updateServerStatus();
      if (!isHealthy) {
        throw new Error('Server is currently unavailable. Please try again later.');
      }

      console.log('Fetching data with timeframe:', timeframe, 'coins:', selectedCoins);
      const result = await analyticsService.getPriceHistory(timeframe, selectedCoins);
      console.log('Fetch result:', result);

      if (result.error) {
        throw new Error(result.error);
      }

      if (!result?.history?.length) {
        console.log('No history data available');
        setError('No price data available for the selected timeframe');
      } else {
        setData(result);
        setError(null);
      }

      setLastUpdated(new Date());
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [timeframe, selectedCoins, updateServerStatus]);

  // Calculate current stats from price history
  const calculateStats = () => {
    if (!data?.history?.length) return {};

    const history = data.history;
    const latestDay = history[history.length - 1];
    const currentPrice = latestDay.lowestPrice.amount;
    const bestSource = latestDay.lowestPrice.source;

    // Calculate 24h change if we have at least 2 days of data
    let priceChange = null;
    if (history.length > 1) {
      const previousDay = history[history.length - 2];
      const previousPrice = previousDay.lowestPrice.amount;
      priceChange = ((currentPrice - previousPrice) / previousPrice) * 100;
    }

    return { 
      currentPrice, 
      priceChange, 
      bestSource,
      lowestPrice: data.priceAnalysis?.lowestPriceEver,
      highestPrice: data.priceAnalysis?.highestPriceEver
    };
  };

  // Initial fetch
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Auto-refresh every 5 minutes if server is online
  useEffect(() => {
    if (serverStatus === 'online') {
      const interval = setInterval(fetchData, 5 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [serverStatus, fetchData]);

  const stats = calculateStats();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">Price Analytics</h1>
        <div className="flex items-center space-x-4">
          {/* Server Status */}
          <div className="flex items-center space-x-2">
            <Server className={`h-4 w-4 ${
              serverStatus === 'online' ? 'text-green-500' :
              serverStatus === 'offline' ? 'text-red-500' :
              'text-yellow-500 animate-pulse'
            }`} />
            <span className="text-sm text-gray-400">
              {serverStatus === 'online' ? 'Server Online' :
               serverStatus === 'offline' ? 'Server Offline' :
               'Checking Server...'}
            </span>
          </div>

          {/* Coin Amount selector */}
          <div className="flex items-center space-x-2 bg-[#1a1f37] border border-[#253053] rounded-md px-2">
            <Coins className="h-4 w-4 text-[#60a5fa]" />
            <select
              value={selectedCoins}
              onChange={(e) => setSelectedCoins(Number(e.target.value))}
              className="bg-transparent text-white border-none rounded-md py-1 text-sm focus:outline-none focus:ring-0"
              disabled={serverStatus !== 'online'}
            >
              {coinAmountOptions.map(option => (
                <option key={option.value} value={option.value} className="bg-[#1a1f37]">
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {/* Timeframe selector */}
          <div className="flex items-center space-x-2 bg-[#1a1f37] border border-[#253053] rounded-md px-2">
            <Calendar className="h-4 w-4 text-[#60a5fa]" />
            <select
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value)}
              className="bg-transparent text-white border-none rounded-md py-1 text-sm focus:outline-none focus:ring-0"
              disabled={serverStatus !== 'online'}
            >
              {timeframeOptions.map(option => (
                <option key={option.value} value={option.value} className="bg-[#1a1f37]">
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {lastUpdated && (
            <div className="text-sm text-gray-400">
              Last updated: {lastUpdated.toLocaleTimeString()}
            </div>
          )}
          
          <button
            onClick={fetchData}
            disabled={isLoading || serverStatus !== 'online'}
            className="flex items-center space-x-2 px-3 py-1 bg-[#1a1f37] border border-[#253053] hover:bg-[#253053] text-[#60a5fa] rounded-md transition-colors disabled:opacity-50"
          >
            <RefreshCcw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
            <span className="text-sm">Refresh</span>
          </button>
        </div>
      </div>

      <div className="space-y-6">
        {/* Price Stats */}
        {stats.currentPrice && (
          <PriceStats
            currentPrice={stats.currentPrice}
            priceChange={stats.priceChange}
            bestSource={stats.bestSource}
            lowestPrice={stats.lowestPrice}
            highestPrice={stats.highestPrice}
          />
        )}

        {/* Price History Chart */}
        <div className="relative">
          <PriceChart
            data={data}
            isLoading={isLoading}
            error={error}
          />
          {isLoading && (
            <div className="absolute top-4 right-4">
              <RefreshCcw className="h-5 w-5 text-gray-400 animate-spin" />
            </div>
          )}
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-yellow-500/10 border border-yellow-500/20 rounded-lg p-4">
            <div className="flex items-center space-x-3">
              <AlertTriangle className="h-5 w-5 text-yellow-500" />
              <span className="text-yellow-500">{error}</span>
            </div>
          </div>
        )}

        {/* Data Range Info */}
        {data?.timeRange && (
          <div className="text-sm text-gray-400 text-center">
            Showing data from {new Date(data.timeRange.start).toLocaleDateString()} to{' '}
            {new Date(data.timeRange.end).toLocaleDateString()}
          </div>
        )}
      </div>
    </div>
  );
};

export default Analytics;
