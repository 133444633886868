import { motion } from 'framer-motion';
import { Clock, Gift, ExternalLink, Monitor, CreditCard, RefreshCw, AlertTriangle, AlertCircle, Package } from 'lucide-react';
import { useForm } from '../../context/FormContext';

function ServiceStatusMessage({ status, error, details }) {
  const getStatusContent = () => {
    switch (status) {
      case 'updating':
        return {
          icon: <RefreshCw className="w-8 h-8 text-yellow-400 animate-spin" />,
          title: 'Price Service Updating',
          message: details?.message || 'Our system is gathering the latest prices.',
          suggestion: details?.suggestion || 'Please try again in a few minutes.'
        };
      case 'unavailable':
        return {
          icon: <AlertTriangle className="w-8 h-8 text-red-400" />,
          title: 'Service Temporarily Unavailable',
          message: details?.message || 'The price comparison service is currently unavailable.',
          suggestion: details?.suggestion || 'Please try again later.'
        };
      default:
        return {
          icon: <AlertTriangle className="w-8 h-8 text-yellow-400" />,
          title: error || 'Error',
          message: details?.message || 'Unable to fetch prices at the moment.',
          suggestion: details?.suggestion || 'Please try again.'
        };
    }
  };

  const content = getStatusContent();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="mt-6 text-center p-6 bg-indigo-900/30 rounded-lg backdrop-blur-sm"
    >
      <div className="flex flex-col items-center space-y-4">
        {content.icon}
        <h3 className="text-xl font-bold text-white">
          {content.title}
        </h3>
        <div className="space-y-2">
          <p className="text-gray-300">{content.message}</p>
          <p className="text-gray-400 text-sm">{content.suggestion}</p>
        </div>
      </div>
    </motion.div>
  );
}

function LoadingState() {
  return (
    <div className="flex items-center justify-center p-8">
      <RefreshCw className="w-6 h-6 text-yellow-400 animate-spin" />
      <span className="ml-3 text-gray-300">Fetching latest prices...</span>
    </div>
  );
}

function formatTimeAgo(minutes) {
  if (minutes < 60) {
    return `${minutes} minutes ago`;
  }
  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }
  const days = Math.floor(hours / 24);
  return `${days} day${days > 1 ? 's' : ''} ago`;
}

function CombinedOrderDetails({ orders, currency }) {
  const formatPrice = (price, currency) => {
    const currencySymbol = currency === 'USD' ? '$' : currency === 'EUR' ? '€' : currency;
    return `${currencySymbol}${price.toFixed(2)}`;
  };

  return (
    <div className="mt-3 p-3 bg-indigo-900/50 rounded-lg">
      <div className="flex items-center mb-2 text-yellow-400">
        <Package className="w-4 h-4 mr-1" />
        <span className="text-sm">Combined Order Details</span>
      </div>
      <div className="space-y-2">
        {orders.map((order, index) => (
          <div key={index} className="flex justify-between items-center text-sm text-gray-300">
            <span>{order.fifaCredits.toLocaleString()} coins</span>
            <span>{formatPrice(order.price.amount, currency)}</span>
          </div>
        ))}
      </div>
      <div className="mt-3 text-xs text-gray-400">
        Note: This price is achieved by combining multiple orders. Total price might vary slightly.
      </div>
    </div>
  );
}

function ResultItem({ site, price, currency = 'USD', deliveryTime, bonus, url, platform, method, isBestDeal, lastChecked, isStale, age, isCombinedOrder, orders }) {
  const formatPrice = (price, currency) => {
    const currencySymbol = currency === 'USD' ? '$' : currency === 'EUR' ? '€' : currency;
    return `${currencySymbol}${price.toFixed(2)}`;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className={`p-4 rounded-lg ${isBestDeal ? 'bg-green-600' : 'bg-indigo-800'} mb-2 transition-colors duration-300 hover:bg-opacity-90`}
    >
      <div className="flex flex-col space-y-3">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2">
          <div className="flex items-center">
            <span className="font-bold text-lg text-white">{site}</span>
            {isBestDeal && (
              <span className="ml-2 text-xs bg-yellow-400 text-indigo-900 px-2 py-1 rounded-full font-semibold">
                Best Deal
              </span>
            )}
            {isStale && (
              <span className="ml-2 flex items-center text-xs text-yellow-400">
                <AlertCircle className="w-4 h-4 mr-1" />
                Price may be outdated
              </span>
            )}
          </div>
          <span className="text-xl font-bold text-white">{formatPrice(price, currency)}</span>
        </div>
        
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3 text-sm">
          <div className="flex flex-wrap gap-3">
            {platform && (
              <div className="flex items-center text-gray-300 min-w-[100px]">
                <Monitor className="w-4 h-4 mr-1 flex-shrink-0" />
                <span>{platform}</span>
              </div>
            )}
            {method && (
              <div className="flex items-center text-gray-300 min-w-[100px]">
                <CreditCard className="w-4 h-4 mr-1 flex-shrink-0" />
                <span>{method.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}</span>
              </div>
            )}
            {deliveryTime && deliveryTime.estimation && (
              <div className="flex items-center text-gray-300">
                <Clock className="w-4 h-4 mr-1 flex-shrink-0" />
                <span>{deliveryTime.estimation}</span>
              </div>
            )}
            {bonus && (
              <div className="flex items-center text-yellow-400">
                <Gift className="w-4 h-4 mr-1 flex-shrink-0" />
                <span>{bonus.description}</span>
              </div>
            )}
            {age !== undefined && (
              <div className="flex items-center text-gray-400">
                <RefreshCw className="w-4 h-4 mr-1 flex-shrink-0" />
                <span>Updated {formatTimeAgo(age)}</span>
              </div>
            )}
          </div>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center px-4 py-2 bg-yellow-400 text-indigo-900 rounded hover:bg-yellow-500 transition-colors font-semibold text-sm w-full sm:w-auto"
          >
            Buy Now
            <ExternalLink className="w-4 h-4 ml-1" />
          </a>
        </div>

        {isCombinedOrder && orders && (
          <CombinedOrderDetails orders={orders} currency={currency} />
        )}
      </div>
    </motion.div>
  );
}

export function ResultsSection({ results, coinsAmount }) {
  const { state } = useForm();
  const { 
    console: selectedPlatform, 
    method: selectedMethod 
  } = state.steps;
  const { 
    isLoading, 
    error, 
    errorDetails,
    serviceStatus,
    priceMetadata 
  } = state;

  const formatCoinsAmount = (amount) => {
    if (amount >= 1000000) {
      return `${(amount / 1000000)}M`;
    }
    return `${(amount / 1000)}k`;
  };

  if (isLoading) {
    return <LoadingState />;
  }

  if (error || serviceStatus !== 'ready') {
    return (
      <ServiceStatusMessage 
        status={serviceStatus}
        error={error}
        details={errorDetails}
      />
    );
  }

  if (!results || !Array.isArray(results) || results.length === 0) {
    return null;
  }

  const normalizePlatform = (platform) => {
    if (!platform) return '';
    platform = platform.toLowerCase();
    if (platform.includes('ps4') || platform.includes('ps5') || platform.includes('playstation')) {
      return 'playstation';
    }
    if (platform.includes('xbox')) {
      return 'xbox';
    }
    if (platform.includes('pc')) {
      return 'pc';
    }
    return platform;
  };

  const normalizeMethod = (method) => {
    if (!method) return '';
    method = method.toLowerCase();
    method = method.replace(/[_\s-]+/g, '');
    if (method.includes('comfort') || method.includes('trade')) {
      return 'comforttrade';
    }
    return method;
  };

  const filteredResults = results
    .filter(result => {
      const platformMatch = normalizePlatform(result.platform) === normalizePlatform(selectedPlatform.value);
      const methodMatch = normalizeMethod(result.method) === normalizeMethod(selectedMethod.value);
      return platformMatch && methodMatch;
    })
    .slice(0, 5); // Only show top 5 results

  if (filteredResults.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="mt-6 text-center p-4 bg-indigo-900/30 rounded-lg backdrop-blur-sm"
      >
        <h3 className="text-xl font-bold mb-4 text-white">
          No results found for your selection
        </h3>
        <p className="text-gray-300">
          Try adjusting your platform or method preferences
        </p>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="mt-6"
    >
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <h3 className="text-xl font-bold text-white text-center sm:text-left">
          Best Deals for {formatCoinsAmount(coinsAmount)} Coins
        </h3>
        {priceMetadata?.lastUpdated && (
          <div className="text-sm text-gray-400 flex items-center mt-2 sm:mt-0">
            <RefreshCw className="w-4 h-4 mr-1" />
            Last updated {formatTimeAgo(Math.round((Date.now() - new Date(priceMetadata.lastUpdated).getTime()) / (60 * 1000)))}
          </div>
        )}
      </div>
      <div className="space-y-3">
        {filteredResults.map((result, index) => (
          <ResultItem
            key={`${result.site}-${index}`}
            {...result}
            isBestDeal={index === 0}
          />
        ))}
      </div>
    </motion.div>
  );
}

export default ResultsSection;
