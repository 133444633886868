import React from 'react';
import { TrendingUp, TrendingDown, ArrowDown, ArrowUp } from 'lucide-react';

const PriceStats = ({ currentPrice, priceChange, bestSource, lowestPrice, highestPrice }) => {
  const formatPrice = (price) => {
    return typeof price === 'number' ? `€${price.toFixed(2)}` : '---';
  };

  const formatPercentage = (percentage) => {
    if (typeof percentage !== 'number') return null;
    const formattedValue = Math.abs(percentage).toFixed(2);
    return percentage >= 0 ? `+${formattedValue}%` : `-${formattedValue}%`;
  };

  const getPriceChangeColor = () => {
    if (typeof priceChange !== 'number') return 'text-gray-500';
    return priceChange >= 0 ? 'text-[#28a745]' : 'text-[#dc3545]';
  };

  const statCardStyle = "bg-[#1a1f37] rounded-xl border border-[#253053] shadow-[0_4px_12px_0_rgba(0,0,0,0.3)] transform transition-all duration-200 hover:translate-y-[-2px] hover:shadow-[0_6px_16px_0_rgba(0,0,0,0.4)] p-4";
  const labelStyle = "text-sm text-gray-400 mb-1";
  const valueStyle = "text-2xl font-bold text-white";
  const subtextStyle = "text-sm text-gray-400 mt-1";

  return (
    <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
      {/* Current Price */}
      <div className={statCardStyle}>
        <div className={labelStyle}>Current Lowest Price</div>
        <div className={valueStyle}>{formatPrice(currentPrice)}</div>
        {bestSource && (
          <div className={subtextStyle}>
            Best price from {bestSource}
          </div>
        )}
      </div>

      {/* 24h Change */}
      <div className={statCardStyle}>
        <div className={labelStyle}>24h Change</div>
        <div className={`${valueStyle} flex items-center ${getPriceChangeColor()}`}>
          {priceChange >= 0 ? (
            <TrendingUp className="h-6 w-6 mr-2" />
          ) : (
            <TrendingDown className="h-6 w-6 mr-2" />
          )}
          {formatPercentage(priceChange) || '---'}
        </div>
        <div className={subtextStyle}>
          Last 24 hours
        </div>
      </div>

      {/* Lowest Price Ever */}
      <div className={statCardStyle}>
        <div className={labelStyle}>Lowest Price Ever</div>
        <div className={`${valueStyle} flex items-center text-[#4ade80]`}>
          <ArrowDown className="h-6 w-6 mr-2" />
          {formatPrice(lowestPrice)}
        </div>
        <div className={subtextStyle}>
          Historical low
        </div>
      </div>

      {/* Highest Price Ever */}
      <div className={statCardStyle}>
        <div className={labelStyle}>Highest Price Ever</div>
        <div className={`${valueStyle} flex items-center text-[#f87171]`}>
          <ArrowUp className="h-6 w-6 mr-2" />
          {formatPrice(highestPrice)}
        </div>
        <div className={subtextStyle}>
          Historical high
        </div>
      </div>

      {/* Best Value Source */}
      <div className={statCardStyle}>
        <div className={labelStyle}>Best Value Source Today</div>
        <div className={`${valueStyle} text-[#60a5fa]`}>
          {bestSource || '---'}
        </div>
        <div className={subtextStyle}>
          Most competitive pricing
        </div>
      </div>
    </div>
  );
};

export default PriceStats;
