'use client';

import { useState } from "react";
import { Gamepad2, Menu, X, Home, BarChart2, Calculator, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

export function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isExternalSite = window.location.hostname === 'www.gamecoins4less.com';
  const currentPath = window.location.pathname;

  const navigationItems = [
    {
      icon: Home,
      label: 'Home',
      path: '/',
      externalPath: 'https://www.gamecoins4less.com',
      description: 'Return to homepage'
    },
    {
      icon: Calculator,
      label: 'Price Checker',
      path: '/price-checker',
      externalPath: 'https://www.gamecoins4less.com/price-checker',
      description: 'Compare game coin prices'
    },
    {
      icon: BarChart2,
      label: 'Analytics',
      path: '/analytics',
      externalPath: 'https://www.gamecoins4less.com/analytics',
      description: 'View price trends'
    }
  ];

  // Mobile menu items now only include navigation items
  const menuItems = [...navigationItems];

  const handleNavigation = (item) => {
    if (isExternalSite || item.externalPath.startsWith('https://www.gamecoins4less.com')) {
      window.location.href = item.externalPath;
    } else {
      window.location.href = item.path;
    }
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="sticky top-0 z-50 w-full border-b border-indigo-800/20 bg-[#1e1b4b]">
      <div className="container flex h-16 items-center justify-between px-4 mx-auto">
        <div className="flex items-center space-x-3">
          <div 
            onClick={() => handleNavigation(navigationItems[0])}
            className="flex items-center space-x-3 cursor-pointer"
          >
            <Gamepad2 className="h-6 w-6 text-[#fbbf24]" />
            <span className="text-lg font-bold text-white sm:text-xl">gamecoins4less</span>
          </div>
        </div>

        <div className="flex items-center space-x-3">
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-3">
            {navigationItems.map((item) => (
              <div
                key={item.path}
                onClick={() => handleNavigation(item)}
                className={`relative group px-4 py-2 text-sm cursor-pointer ${
                  currentPath === item.path ? 'text-[#fbbf24]' : 'text-gray-200 hover:text-[#fbbf24]'
                } transition-colors`}
              >
                <span className="flex items-center space-x-2">
                  <item.icon className="h-4 w-4" />
                  <span>{item.label}</span>
                </span>
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-[#fbbf24] transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left"></span>
              </div>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden relative w-10 h-10 flex items-center justify-center text-gray-200 hover:text-[#fbbf24] transition-colors focus:outline-none"
            aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
          >
            <AnimatePresence mode="wait">
              {isMobileMenuOpen ? (
                <motion.div
                  key="close"
                  initial={{ rotate: -90, opacity: 0 }}
                  animate={{ rotate: 0, opacity: 1 }}
                  exit={{ rotate: 90, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <X className="h-6 w-6" />
                </motion.div>
              ) : (
                <motion.div
                  key="menu"
                  initial={{ rotate: -90, opacity: 0 }}
                  animate={{ rotate: 0, opacity: 1 }}
                  exit={{ rotate: 90, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <Menu className="h-6 w-6" />
                </motion.div>
              )}
            </AnimatePresence>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40 md:hidden"
              onClick={() => setIsMobileMenuOpen(false)}
            />
            <motion.div
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}
              transition={{ type: "spring", damping: 25, stiffness: 300 }}
              className="fixed right-0 top-0 bottom-0 w-[280px] bg-[#1e1b4b] z-50 md:hidden border-l border-indigo-800/20"
            >
              <div className="flex flex-col h-full">
                <div className="p-6 border-b border-indigo-800/20">
                  <div className="flex items-center space-x-2">
                    <Gamepad2 className="h-6 w-6 text-[#fbbf24]" />
                    <span className="text-lg font-bold text-white">gamecoins4less</span>
                  </div>
                </div>

                <nav className="flex-1 p-6">
                  <div className="space-y-3">
                    {menuItems.map((item, index) => (
                      <div
                        key={item.path}
                        onClick={() => handleNavigation(item)}
                        className={`w-full flex items-center justify-between p-4 rounded-lg transition-colors group cursor-pointer ${
                          currentPath === item.path
                            ? 'text-[#fbbf24] bg-indigo-800/30'
                            : 'text-gray-200 hover:text-[#fbbf24] hover:bg-indigo-800/30'
                        }`}
                      >
                        <div className="flex items-center space-x-3">
                          <item.icon className="h-5 w-5" />
                          <div className="text-left">
                            <div className="font-medium">{item.label}</div>
                            <div className="text-sm text-gray-400 group-hover:text-gray-300">
                              {item.description}
                            </div>
                          </div>
                        </div>
                        <ChevronRight className="h-4 w-4 transform transition-transform group-hover:translate-x-1" />
                      </div>
                    ))}
                  </div>
                </nav>

                <div className="p-6 border-t border-indigo-800/20">
                  <div className="text-xs text-gray-400">
                    © {new Date().getFullYear()} gamecoins4less
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </header>
  );
}
