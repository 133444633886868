import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Home from './pages/Home';
import PriceChecker from './pages/PriceChecker';
import Analytics from './pages/Analytics';
import { FormProvider } from './context/FormContext';
import { inject as injectAnalytics } from '@vercel/analytics';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    injectAnalytics();
    injectSpeedInsights();
  }, []);

  // Check if we're on gamecoins4less.com/price-checker
  const isExternalPriceChecker = window.location.hostname === 'www.gamecoins4less.com' && 
                                window.location.pathname === '/price-checker';

  // If we're on the external price checker URL, render only the price checker component
  if (isExternalPriceChecker) {
    return (
      <FormProvider>
        <div className="flex min-h-screen flex-col bg-[#1e1b4b]/95 antialiased">
          <Header />
          <main className="flex-1 w-full py-4 sm:py-6 lg:py-8">
            <div className="container mx-auto">
              <PriceChecker />
            </div>
          </main>
          <Footer />
        </div>

        {/* Mobile Menu Backdrop */}
        <div 
          id="mobile-menu-backdrop"
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40 transition-opacity duration-300 opacity-0 pointer-events-none"
          aria-hidden="true"
        />

        <style jsx global>{`
          html {
            -webkit-tap-highlight-color: transparent;
          }
          
          body {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
          }

          [data-state="open"] ~ #mobile-menu-backdrop {
            opacity: 1;
            pointer-events: auto;
          }

          @supports (-webkit-overflow-scrolling: touch) {
            body {
              -webkit-overflow-scrolling: touch;
              overflow-x: hidden;
            }
          }

          @media (hover: none) {
            button:active {
              opacity: 0.8;
            }
          }

          html {
            -webkit-text-size-adjust: 100%;
          }
        `}</style>
      </FormProvider>
    );
  }

  // For local development or other pages on gamecoins4less.com
  return (
    <Router>
      <FormProvider>
        <div className="flex min-h-screen flex-col bg-[#1e1b4b]/95 antialiased">
          <Header />
          <main className="flex-1 w-full py-4 sm:py-6 lg:py-8">
            <div className="container mx-auto">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/price-checker" element={<PriceChecker />} />
                <Route path="/analytics" element={<Analytics />} />
              </Routes>
            </div>
          </main>
          <Footer />
        </div>

        {/* Mobile Menu Backdrop */}
        <div 
          id="mobile-menu-backdrop"
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40 transition-opacity duration-300 opacity-0 pointer-events-none"
          aria-hidden="true"
        />

        <style jsx global>{`
          html {
            -webkit-tap-highlight-color: transparent;
          }
          
          body {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
          }

          [data-state="open"] ~ #mobile-menu-backdrop {
            opacity: 1;
            pointer-events: auto;
          }

          @supports (-webkit-overflow-scrolling: touch) {
            body {
              -webkit-overflow-scrolling: touch;
              overflow-x: hidden;
            }
          }

          @media (hover: none) {
            button:active {
              opacity: 0.8;
            }
          }

          html {
            -webkit-text-size-adjust: 100%;
          }
        `}</style>
      </FormProvider>
    </Router>
  );
}

export default App;
