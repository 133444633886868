import React from 'react';

const GradientBox = ({ children, className = '' }) => {
  return (
    <div className={`gradient-box ${className}`}>
      <div className="gradient-box-inner">
        {children}
      </div>
    </div>
  );
};

export default GradientBox;
