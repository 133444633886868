import React from 'react';
import { ChartBarIcon, CalculatorIcon } from '@heroicons/react/24/outline';
import Card3D from '../components/ui/Card3D';
import GradientBox from '../components/ui/GradientBox';
import '../styles/card3d.css';
import '../styles/gradientBox.css';

const Home = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <div 
        className="bg-[#1e1b4b]/95 rounded-lg overflow-hidden border border-indigo-500/20 backdrop-blur-sm transform-gpu"
        style={{
          boxShadow: `
            0 0 0 1px rgba(99, 102, 241, 0.1),
            0 4px 6px -1px rgba(99, 102, 241, 0.1),
            0 2px 4px -1px rgba(99, 102, 241, 0.06),
            0 20px 25px -5px rgba(99, 102, 241, 0.1),
            0 10px 10px -5px rgba(99, 102, 241, 0.04),
            0 0 15px rgba(99, 102, 241, 0.2)
          `
        }}
      >
        <div className="px-4 sm:px-6 py-6 sm:py-8">
          <div className="text-center mb-12">
            <h1 className="text-xl sm:text-2xl font-bold text-white mb-4">
              Welcome to GameCoins4Less
            </h1>
            <p className="text-sm sm:text-lg text-gray-300 max-w-2xl mx-auto">
              Your trusted platform for game currency price comparison and market analysis
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-16 place-items-center max-w-5xl mx-auto mb-12">
            <Card3D 
              to="/price-checker"
              title="Price Checker"
              description="Compare prices across different platforms and find the best deals"
              icon={CalculatorIcon}
              gradient="bg-gradient-to-br from-[#60a5fa] to-[#3b82f6]"
              iconColor="text-white"
            />

            <Card3D 
              to="/analytics"
              title="Analytics"
              description="Track price trends and market analysis over time"
              icon={ChartBarIcon}
              gradient="bg-gradient-to-br from-[#10b981] to-[#059669]"
              iconColor="text-white"
            />
          </div>

          <div className="max-w-4xl mx-auto">
            <GradientBox>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h3 className="text-lg font-semibold text-white mb-3">
                    Real-time Price Comparison
                  </h3>
                  <p className="text-gray-300 leading-relaxed">
                    Get instant access to current market prices across multiple platforms
                  </p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-3">
                    Market Insights
                  </h3>
                  <p className="text-gray-300 leading-relaxed">
                    Make informed decisions with our detailed price analytics
                  </p>
                </div>
              </div>
            </GradientBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
