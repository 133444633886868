'use client';

import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Gamepad2, Mail } from 'lucide-react';
import GradientBox from './ui/GradientBox';
import '../styles/gradientBox.css';

export function Footer() {
  const isExternalSite = window.location.hostname === 'www.gamecoins4less.com';

  const NavigationLink = ({ to, children, className }) => {
    if (isExternalSite) {
      return (
        <a href={`https://www.gamecoins4less.com${to}`} className={className}>
          {children}
        </a>
      );
    }
    return (
      <Link to={to} className={className}>
        {children}
      </Link>
    );
  };

  return (
    <footer className="border-t border-indigo-800/20 bg-[#1e1b4b]">
      <div className="container px-4 py-8 sm:py-12 mx-auto">
        <div className="flex flex-col items-center justify-center space-y-8">
          {/* Contact Information */}
          <div className="text-center">
            <NavigationLink 
              to="/"
              className="flex items-center justify-center space-x-2 mb-4"
            >
              <Gamepad2 className="h-5 w-5 sm:h-6 sm:w-6 text-[#fbbf24]" />
              <span className="text-base sm:text-lg font-bold text-white">gamecoins4less</span>
            </NavigationLink>
            <div className="text-sm text-gray-300">
              <div className="flex items-center justify-center space-x-2">
                <Mail className="h-4 w-4" />
                <span>info@gamecoins4less.com</span>
              </div>
            </div>
          </div>

          {/* Feedback Banner */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
            className="w-full max-w-3xl"
          >
            <GradientBox>
              <div className="text-center py-4">
                <div className="text-2xl sm:text-3xl font-bold text-white mb-2">
                  Your Feedback Powers Our Progress! 🎮
                </div>
                <div className="text-lg sm:text-xl text-white/90">
                  Help us level up - we're building this together!
                </div>
              </div>
            </GradientBox>
          </motion.div>

          {/* Bottom Bar */}
          <div className="w-full pt-8 text-center text-sm text-gray-400 border-t border-indigo-800/20">
            © {new Date().getFullYear()} gamecoins4less. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
