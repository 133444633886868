import React from 'react';
import { cn } from '../../utils/cn';

export const Button = React.forwardRef(({ 
  className, 
  variant = "default", 
  size = "default", 
  asChild = false,
  children,
  ...props 
}, ref) => {
  const baseStyles = `
    inline-flex items-center justify-center rounded-lg font-medium transition-all
    focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-400
    disabled:pointer-events-none disabled:opacity-50
    active:scale-[0.98] transform-gpu
    touch-none
  `;
  
  const variants = {
    default: "bg-indigo-600 text-white hover:bg-indigo-700 active:bg-indigo-800",
    ghost: "hover:bg-indigo-100/10 hover:text-indigo-100 active:bg-indigo-100/20",
    link: "text-indigo-100 hover:text-indigo-200 p-0 h-auto bg-transparent hover:bg-transparent",
    icon: "h-10 w-10 p-0",
  };

  const sizes = {
    default: "h-10 px-4 py-2 text-sm",
    sm: "h-9 px-3 text-xs",
    lg: "h-11 px-8 text-base",
    icon: "h-10 w-10",
  };

  const Comp = asChild ? React.Fragment : "button";

  return (
    <Comp
      className={cn(
        baseStyles,
        variants[variant],
        sizes[size],
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
    </Comp>
  );
});

Button.displayName = "Button";
