import React from 'react';
import { Link } from 'react-router-dom';

const Card3D = ({ 
  to, 
  title, 
  description, 
  icon: Icon,
  gradient,
  iconColor 
}) => {
  return (
    <div className="parent">
      <Link to={to} className="block w-full h-full">
        <div className={`card ${gradient}`}>
          <div className="logo">
            <span className="circle circle1"></span>
            <span className="circle circle2"></span>
            <span className="circle circle3"></span>
            <span className="circle circle4"></span>
            <span className="circle circle5">
              <Icon className={`svg ${iconColor}`} />
            </span>
          </div>
          
          <div className="glass"></div>
          
          <div className="content">
            <span className="title">{title}</span>
            <span className="text">{description}</span>
          </div>
          
          <div className="bottom">
            <div className="view-more">
              <button className="view-more-button">View more</button>
              <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round">
                <path d="m6 9 6 6 6-6"></path>
              </svg>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card3D;
