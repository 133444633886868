import { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Gamepad2, Shield, Percent, Coins, CreditCard } from 'lucide-react';
import { ConsoleStep } from './steps/ConsoleStep';
import { MethodStep } from './steps/MethodStep';
import { CoinsStep } from './steps/CoinsStep';
import { ResultsSection } from './steps/ResultsSection';
import { NavigationButtons } from './steps/NavigationButtons';
import { useForm } from '../context/FormContext';

const steps = [
  { id: 'console', icon: Gamepad2, label: 'Console' },
  { id: 'method', icon: CreditCard, label: 'Method' },
  { id: 'coins', icon: Coins, label: 'Coins' },
];

const NavItem = ({ icon: Icon, label, isActive, isCompleted, onClick }) => (
  <div
    className={`
      flex-1 cursor-pointer transition-all duration-300 border relative
      ${isActive 
        ? 'bg-[#fbbf24] border-[#fbbf24] text-[#1e1b4b]' 
        : 'bg-transparent border-indigo-800/30 hover:border-[#fbbf24]/30 text-white'}
    `}
    onClick={onClick}
  >
    <div className="flex flex-col items-center justify-center py-2 sm:py-5">
      <motion.div
        animate={isActive ? { 
          rotate: [0, 360],
          scale: [1, 1.2, 1]
        } : {}}
        transition={{ 
          duration: 0.5,
          times: [0, 0.6, 1]
        }}
      >
        <Icon className={`h-4 w-4 sm:h-6 sm:w-6 ${isActive ? 'text-[#1e1b4b]' : ''}`} />
      </motion.div>
      <span className={`mt-1 sm:mt-2 text-[10px] sm:text-sm font-medium`}>
        {label}
      </span>
    </div>
  </div>
);

export default function PriceChecker() {
  const { 
    state, 
    updateStep, 
    dispatch,
    canNavigate,
    findBestDeals,
    clearResults
  } = useForm();

  const { currentStep, steps: formSteps, results, isLoading } = state;

  useEffect(() => {
    try {
      const savedData = localStorage.getItem('formData');
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        if (typeof parsedData.coins === 'number') {
          parsedData.coins = {
            amount: parsedData.coins,
            calculatedPrice: null  // Don't calculate price locally
          };
        }
        dispatch({ type: 'RESTORE_DATA', payload: parsedData });
      }
    } catch (error) {
      console.error('Error restoring form data:', error);
      localStorage.removeItem('formData');
    }
  }, [dispatch]);

  useEffect(() => {
    try {
      localStorage.setItem('formData', JSON.stringify({
        console: formSteps.console.value,
        method: formSteps.method.value,
        coins: formSteps.coins.value.amount
      }));
    } catch (error) {
      console.error('Error saving form data:', error);
    }
  }, [formSteps]);

  useEffect(() => {
    if (currentStep !== 'coins') {
      clearResults();
    }
  }, [currentStep, clearResults]);

  const handleNext = () => {
    const currentIndex = steps.findIndex(step => step.id === currentStep);
    if (currentIndex < steps.length - 1) {
      const nextStep = steps[currentIndex + 1].id;
      if (canNavigate(currentStep, nextStep)) {
        dispatch({ type: 'SET_STEP', step: nextStep });
      }
    }
  };

  const handlePrevious = () => {
    const currentIndex = steps.findIndex(step => step.id === currentStep);
    if (currentIndex > 0) {
      dispatch({ type: 'SET_STEP', step: steps[currentIndex - 1].id });
    }
  };

  const handleConsoleSelect = (console) => {
    updateStep('console', console);
  };

  const handleMethodSelect = (method) => {
    updateStep('method', method);
  };

  const handleCoinsChange = (amount) => {
    // Only update amount, don't calculate price locally
    updateStep('coins', { 
      amount, 
      calculatedPrice: null 
    });
  };

  const handleFindDeals = async () => {
    await findBestDeals();
  };

  const getStepContent = () => {
    switch (currentStep) {
      case 'console':
        return (
          <ConsoleStep
            selected={formSteps.console.value}
            onSelect={handleConsoleSelect}
            isValid={formSteps.console.isValid}
          />
        );
      case 'method':
        return (
          <MethodStep
            selected={formSteps.method.value}
            onSelect={handleMethodSelect}
            isValid={formSteps.method.isValid}
          />
        );
      case 'coins':
        return (
          <CoinsStep
            value={formSteps.coins.value.amount}
            calculatedPrice={formSteps.coins.value.calculatedPrice}
            onChange={handleCoinsChange}
            onFindDeals={handleFindDeals}
            isLoading={isLoading}
            isValid={formSteps.coins.isValid}
          />
        );
      default:
        return null;
    }
  };

  const showResults = currentStep === 'coins' && results && results.length > 0;

  return (
    <div className="w-full max-w-4xl mx-auto px-4 sm:px-6">
      <motion.div 
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="bg-[#1e1b4b]/95 rounded-lg overflow-hidden shadow-[0_0_15px_rgba(99,102,241,0.2)] border border-indigo-500/20 backdrop-blur-sm transform-gpu"
        style={{
          boxShadow: `
            0 0 0 1px rgba(99, 102, 241, 0.1),
            0 4px 6px -1px rgba(99, 102, 241, 0.1),
            0 2px 4px -1px rgba(99, 102, 241, 0.06),
            0 20px 25px -5px rgba(99, 102, 241, 0.1),
            0 10px 10px -5px rgba(99, 102, 241, 0.04),
            0 0 15px rgba(99, 102, 241, 0.2)
          `
        }}
      >
        <div className="px-4 sm:px-6 py-6 sm:py-8">
          <h1 className="text-xl sm:text-2xl font-bold text-center mb-6 sm:mb-8 text-white">Buy FC 25 Coins (FIFA 25)</h1>
          
          <div className="flex rounded-lg overflow-hidden mb-6">
            {steps.map((step, index) => (
              <div key={step.id} className="flex-1">
                <NavItem
                  icon={step.icon}
                  label={step.label}
                  isActive={currentStep === step.id}
                  isCompleted={formSteps[step.id].isValid}
                  onClick={() => {
                    if (canNavigate(currentStep, step.id)) {
                      dispatch({ type: 'SET_STEP', step: step.id });
                    }
                  }}
                />
              </div>
            ))}
          </div>

          <div className="bg-indigo-900/20 rounded-lg px-4 sm:px-6 py-3 sm:py-4 mt-6 sm:mt-8 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0 backdrop-blur-sm border border-indigo-800/30">
            <div className="flex items-center space-x-2">
              <Shield className="h-4 w-4 sm:h-5 sm:w-5 text-[#fbbf24]" />
              <span className="text-xs sm:text-sm text-gray-200">7 Days Guarantee</span>
            </div>
            <div className="flex items-center space-x-2">
              <Percent className="h-4 w-4 sm:h-5 sm:w-5 text-[#fbbf24]" />
              <span className="text-xs sm:text-sm text-gray-200">5% Tax Covered</span>
            </div>
          </div>

          <div className="mt-4">
            <AnimatePresence mode="wait">
              {getStepContent()}
            </AnimatePresence>

            {showResults && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="mt-6"
              >
                <ResultsSection
                  results={results}
                  coinsAmount={formSteps.coins.value.amount}
                />
              </motion.div>
            )}

            <NavigationButtons
              currentStep={currentStep}
              onPrevious={handlePrevious}
              onNext={handleNext}
              isLastStep={currentStep === 'coins'}
              canNavigateNext={
                currentStep !== 'coins' && 
                canNavigate(currentStep, steps[steps.findIndex(s => s.id === currentStep) + 1]?.id)
              }
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
}
