import { motion } from 'framer-motion';

export function ConsoleStep({ selected, onSelect, isValid }) {
  const platforms = [
    { id: 'playstation', name: 'PlayStation', subtitle: 'PS4 | PS5', price: '50K from 2.35$' },
    { id: 'xbox', name: 'Xbox', subtitle: 'One | Series X|S', price: '25K from 1.65$' },
    { id: 'pc', name: 'PC', subtitle: 'Windows', price: '100K from 3.50$' }
  ];

  const handleSelect = (platformId) => {
    onSelect(platformId.toLowerCase());
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4 mt-6 sm:mt-8"
    >
      {platforms.map((platform) => (
        <motion.div
          key={platform.id}
          className={`
            cursor-pointer rounded-lg border transition-all duration-300 transform-gpu
            backdrop-blur-sm shadow-lg
            ${selected === platform.id 
              ? 'bg-[#1e1b4b] border-[#fbbf24] shadow-[0_0_10px_rgba(251,191,36,0.2)]' 
              : 'bg-[#1e1b4b] border-indigo-800/30 hover:border-[#fbbf24]/30 shadow-[0_0_10px_rgba(99,102,241,0.2)]'}
          `}
          onClick={() => handleSelect(platform.id)}
          whileHover={{ 
            scale: 1.02,
            y: -4,
            transition: { duration: 0.2 }
          }}
          whileTap={{ scale: 0.98 }}
          style={{
            boxShadow: selected === platform.id
              ? `
                0 0 0 1px rgba(251, 191, 36, 0.2),
                0 4px 6px -1px rgba(251, 191, 36, 0.1),
                0 2px 4px -1px rgba(251, 191, 36, 0.06),
                0 10px 15px -3px rgba(251, 191, 36, 0.1)
              `
              : `
                0 0 0 1px rgba(99, 102, 241, 0.1),
                0 4px 6px -1px rgba(99, 102, 241, 0.1),
                0 2px 4px -1px rgba(99, 102, 241, 0.06),
                0 10px 15px -3px rgba(99, 102, 241, 0.1)
              `
          }}
        >
          <div className="py-4 sm:py-6 px-3 sm:px-4 text-center">
            <div className="text-xl sm:text-2xl font-bold text-white mb-1">{platform.name}</div>
            <div className="text-xs sm:text-sm text-gray-400 mb-2 sm:mb-3">{platform.subtitle}</div>
            <div className="text-[#fbbf24] font-medium">
              <span className="text-base sm:text-lg">{platform.price.split(' ')[0]}</span>
              <span className="text-xs sm:text-sm opacity-90"> {platform.price.split(' ').slice(1).join(' ')}</span>
            </div>
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
}

export default ConsoleStep;
