import { motion } from 'framer-motion';
import { Button } from '../ui/Button';
import { ArrowLeft, ArrowRight } from 'lucide-react';

export function NavigationButtons({ currentStep, onPrevious, onNext, isLastStep, canNavigateNext }) {
  return (
    <div className="mt-6 sm:mt-8 flex justify-between items-center">
      {currentStep !== 'console' && (
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
        >
          <Button 
            onClick={onPrevious} 
            className="bg-indigo-900/50 text-white hover:bg-indigo-900/70 px-3 sm:px-6 h-9 sm:h-10 text-sm sm:text-base"
          >
            <ArrowLeft className="mr-1 sm:mr-2 h-4 w-4" />
            <span className="hidden xs:inline">Previous</span>
          </Button>
        </motion.div>
      )}

      {!isLastStep && (
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          className={`${currentStep === 'console' ? '' : 'ml-auto'}`}
        >
          <Button 
            onClick={onNext}
            disabled={!canNavigateNext}
            className={`
              bg-[#fbbf24] text-[#1e1b4b] hover:bg-[#f59e0b] font-medium 
              px-3 sm:px-6 h-9 sm:h-10 text-sm sm:text-base
              disabled:opacity-50 disabled:cursor-not-allowed
              transition-colors duration-200
            `}
          >
            <span className="hidden xs:inline">Next</span>
            <ArrowRight className="ml-1 sm:ml-2 h-4 w-4" />
          </Button>
        </motion.div>
      )}
    </div>
  );
}

export default NavigationButtons;
