import React from 'react';
import { cn } from "../../utils/cn";

const Slider = React.forwardRef(({ 
  className,
  value = [0],
  onValueChange,
  min = 0,
  max = 100,
  step = 1,
  ...props 
}, ref) => {
  const percentage = ((value[0] - min) / (max - min)) * 100;

  const handleChange = (e) => {
    const newValue = Number(e.target.value);
    onValueChange?.([newValue]);
  };

  return (
    <div className="relative w-full" ref={ref}>
      <input
        type="range"
        value={value[0]}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        className={cn(
          "w-full h-2 appearance-none bg-gray-200 rounded-full outline-none cursor-pointer",
          "before:absolute before:h-2",
          "before:bg-yellow-400 before:rounded-full",
          className
        )}
        style={{
          background: `linear-gradient(to right, #FBBF24 ${percentage}%, #e5e7eb ${percentage}%)`
        }}
        {...props}
      />
    </div>
  );
});

Slider.displayName = "Slider";

export { Slider };
