import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { format, parseISO } from 'date-fns';
import { RefreshCcw } from 'lucide-react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-full">
    <div className="text-center space-y-4">
      <RefreshCcw className="h-8 w-8 animate-spin text-[#60a5fa]" />
      <div className="text-gray-400">Loading price data...</div>
    </div>
  </div>
);

const EmptyState = ({ message }) => (
  <div className="flex items-center justify-center h-full">
    <div className="text-center space-y-2">
      <p className="text-gray-400">{message}</p>
      <p className="text-sm text-gray-500">Select a different timeframe or try again later</p>
    </div>
  </div>
);

const PriceChart = ({ data, isLoading, error }) => {
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(26, 31, 55, 0.95)',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 12,
        borderColor: '#253053',
        borderWidth: 1,
        displayColors: false,
        callbacks: {
          title: function(context) {
            try {
              return format(parseISO(context[0].raw.date), 'PPP');
            } catch (e) {
              console.error('Error formatting date:', e);
              return 'Invalid date';
            }
          },
          label: function(context) {
            try {
              const dataPoint = context.raw;
              const lines = [
                `Lowest Price: €${dataPoint.lowestPrice.amount.toFixed(2)}`,
                `Source: ${dataPoint.lowestPrice.source}`
              ];
              
              if (dataPoint.lowestPrice.allSources?.length > 1) {
                lines.push('', 'All prices:');
                dataPoint.lowestPrice.allSources
                  .sort((a, b) => a.price - b.price)
                  .forEach(source => {
                    const isLowest = source.source === dataPoint.lowestPrice.source;
                    lines.push(`${source.source}: €${source.price.toFixed(2)}${isLowest ? ' 🏆' : ''}`);
                  });
              }
              
              return lines;
            } catch (e) {
              console.error('Error formatting tooltip:', e);
              return ['Error displaying price data'];
            }
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: false,
        grid: {
          color: 'rgba(37, 48, 83, 0.5)',
          drawBorder: false
        },
        ticks: {
          callback: function(value) {
            return '€' + value.toFixed(2);
          },
          font: {
            size: 11
          },
          color: '#94a3b8'
        },
        border: {
          display: false
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: 11
          },
          color: '#94a3b8',
          callback: function(value, index) {
            if (window.innerWidth < 768) {
              return index % 2 === 0 ? this.getLabelForValue(value) : '';
            }
            return this.getLabelForValue(value);
          }
        },
        border: {
          display: false
        }
      }
    }
  };

  const getChartData = () => {
    if (!data?.history?.length) {
      console.log('No history data available for chart');
      return null;
    }

    try {
      const sortedHistory = [...data.history].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });

      const chartData = {
        datasets: [
          {
            data: sortedHistory.map(day => ({
              x: format(parseISO(day.date), 'MMM d'),
              y: day.lowestPrice.amount,
              date: day.date,
              lowestPrice: day.lowestPrice
            })),
            borderColor: '#60a5fa',
            backgroundColor: 'rgba(96, 165, 250, 0.1)',
            tension: 0.4,
            fill: true,
            pointRadius: window.innerWidth < 768 ? 4 : 6,
            pointHoverRadius: window.innerWidth < 768 ? 6 : 8,
            pointBackgroundColor: '#60a5fa',
            pointBorderColor: '#1a1f37',
            pointBorderWidth: 2,
            borderWidth: 3
          }
        ]
      };

      return chartData;
    } catch (e) {
      console.error('Error preparing chart data:', e);
      return null;
    }
  };

  const chartHeight = window.innerWidth >= 1200 ? 500 : 
                     window.innerWidth >= 768 ? 400 : 300;

  const chartData = getChartData();

  return (
    <div className="bg-[#1a1f37] rounded-xl border border-[#253053] shadow-[0_4px_12px_0_rgba(0,0,0,0.3)] transform transition-all duration-200 hover:translate-y-[-2px] hover:shadow-[0_6px_16px_0_rgba(0,0,0,0.4)] p-4 md:p-6">
      <div style={{ height: chartHeight }}>
        {isLoading ? (
          <LoadingSpinner />
        ) : error ? (
          <EmptyState message={error} />
        ) : !chartData ? (
          <EmptyState message="No price history available for the selected timeframe" />
        ) : (
          <Line options={chartOptions} data={chartData} />
        )}
      </div>
    </div>
  );
};

export default PriceChart;
