import { motion } from 'framer-motion';

const paymentMethod = {
  id: 'comfort_trade',
  name: 'Comfort Trade',
  description: 'We log in and make the transfer'
};

export function MethodStep({ selected, onSelect, isValid }) {
  // Automatically select comfort trade if nothing is selected
  if (!selected) {
    onSelect(paymentMethod.id);
  }

  return (
    <motion.div
      key="method"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="space-y-4"
    >
      <h3 className="text-lg font-medium text-white">Transfer Method</h3>
      <div className="space-y-6">
        <motion.div
          className="p-4 rounded-lg bg-yellow-400/20 ring-2 ring-yellow-400 shadow-lg shadow-yellow-400/20"
        >
          <div className="flex items-center space-x-3">
            <div className="w-4 h-4 rounded-full border-2 border-yellow-400 bg-yellow-400" />
            <div>
              <h4 className="font-semibold text-white">{paymentMethod.name}</h4>
              <p className="text-sm text-gray-300">{paymentMethod.description}</p>
            </div>
          </div>
        </motion.div>
      </div>
      <p className="text-sm text-gray-400 mt-4">
        Currently, only Comfort Trade method is available for secure transactions.
      </p>
    </motion.div>
  );
}

export default MethodStep;
