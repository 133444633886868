import { motion } from 'framer-motion';
import { Button } from '../ui/Button';
import { Slider } from '../ui/Slider';
import { Minus, Plus, RefreshCw } from 'lucide-react';

export function CoinsStep({ value, calculatedPrice, onChange, onFindDeals, isLoading, isValid }) {
  const handleAdjustAmount = (adjustment) => {
    const newAmount = Math.max(100000, Math.min(5000000, value + adjustment));
    onChange(newAmount);
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatCoinsAmount = (amount) => {
    if (amount >= 1000000) {
      return `${(amount / 1000000)}M`;
    }
    return `${(amount / 1000)}k`;
  };

  const handleFindDealsClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await onFindDeals();
    } catch (error) {
      console.error('Error finding deals:', error);
    }
  };

  const stats = [
    { label: 'Discount', value: 'Save 10%', color: 'yellow-400' },
    { label: 'Coins', value: formatCoinsAmount(value), color: 'yellow-400' },
    { label: 'Delivery', value: 'About 40 Minutes', color: 'yellow-400' }
  ];

  return (
    <motion.div
      key="coins"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="space-y-6"
    >
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
        {stats.map(({ label, value: statValue, color }) => (
          <div 
            key={label} 
            className="bg-indigo-900/30 p-4 rounded-lg backdrop-blur-sm border border-indigo-800/20"
          >
            <div className={`text-${color} font-bold text-sm`}>{label}</div>
            <div className="text-lg text-white mt-1">{statValue}</div>
          </div>
        ))}
      </div>

      <div className="relative mb-12 sm:mb-10 px-2">
        <Slider
          value={[value]}
          onValueChange={(newValue) => onChange(newValue[0])}
          max={5000000}
          step={100000}
          className="py-4"
        />
        <div className="absolute -bottom-6 left-0 right-0 flex justify-between text-xs text-gray-400">
          {['100k', '1M', '2M', '3M', '4M', '5M'].map((label) => (
            <span key={label}>{label}</span>
          ))}
        </div>
      </div>

      <div className="flex items-center justify-center gap-4 mb-6">
        <Button
          variant="outline"
          size="icon"
          onClick={() => handleAdjustAmount(-100000)}
          className="bg-indigo-900/50 text-white hover:bg-indigo-800/50 border-indigo-700"
        >
          <Minus className="h-4 w-4" />
        </Button>
        <div className="w-36 text-center bg-indigo-900/50 py-2 px-4 rounded-md backdrop-blur-sm border border-indigo-800/20">
          <span className="text-xl font-bold text-white">{formatNumber(value)}</span>
        </div>
        <Button
          variant="outline"
          size="icon"
          onClick={() => handleAdjustAmount(100000)}
          className="bg-indigo-900/50 text-white hover:bg-indigo-800/50 border-indigo-700"
        >
          <Plus className="h-4 w-4" />
        </Button>
      </div>

      <div className="bg-indigo-900/30 p-4 rounded-lg flex flex-col sm:flex-row justify-between items-center backdrop-blur-sm border border-indigo-800/20 gap-4">
        <div className="text-center sm:text-left">
          {isLoading ? (
            <div className="flex items-center space-x-2">
              <RefreshCw className="w-5 h-5 text-gray-400 animate-spin" />
              <span className="text-gray-400">Fetching prices...</span>
            </div>
          ) : (
            <>
              <div className="text-2xl font-bold text-white">
                {calculatedPrice ? (
                  `$${calculatedPrice.toFixed(2)}`
                ) : (
                  <span className="text-gray-400">Click "Find Best Deals" for prices</span>
                )}
              </div>
              <div className="text-gray-400">
                {formatCoinsAmount(value)} Coins
              </div>
            </>
          )}
        </div>
        <Button
          onClick={handleFindDealsClick}
          disabled={isLoading || !isValid}
          className={`
            bg-yellow-400 text-indigo-900 hover:bg-yellow-500 px-8 w-full sm:w-auto 
            font-semibold disabled:opacity-50 disabled:cursor-not-allowed transition-colors
            active:scale-95 transform-gpu
          `}
        >
          {isLoading ? (
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              className="w-5 h-5 border-2 border-indigo-900 border-t-transparent rounded-full"
            />
          ) : (
            'Find Best Deals'
          )}
        </Button>
      </div>
    </motion.div>
  );
}

export default CoinsStep;
