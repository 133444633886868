const API_BASE_URL = 'https://gamecoins4less-backend.onrender.com';
const HISTORICAL_PRICES_ENDPOINT = '/api/analytics/historical-prices';

export const analyticsService = {
  async checkHealth() {
    try {
      const response = await fetch(`${API_BASE_URL}/health`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Origin': 'https://www.gamecoins4less.com'
        }
      });

      return response.ok;
    } catch (error) {
      console.error('Health check failed:', error);
      return false;
    }
  },

  async getPriceHistory(timeframe = 'all', fifaCredits = 5000000) {
    try {
      // Always use current date as end date
      const endDate = new Date().toISOString().split('T')[0];
      let startDate;

      // Set start date based on timeframe
      if (timeframe === 'all') {
        startDate = '2024-12-14';  // Fixed start date from December 14th
      } else {
        const end = new Date();
        const days = timeframe === '7d' ? 7 : 3;
        const start = new Date(end);
        start.setDate(end.getDate() - days);
        startDate = start.toISOString().split('T')[0];

        // Ensure start date is not earlier than Dec 14th
        if (start < new Date('2024-12-14')) {
          startDate = '2024-12-14';
        }
      }

      const params = new URLSearchParams({
        platform: 'PLAYSTATION',
        startDate,
        endDate,
        fifaCredits
      });

      const url = `${API_BASE_URL}${HISTORICAL_PRICES_ENDPOINT}?${params}`;
      console.log('Fetching price history...', { 
        url, 
        params: Object.fromEntries(params),
        timeframe,
        dateRange: `${startDate} to ${endDate}`
      });
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Origin': 'https://www.gamecoins4less.com'
        }
      });

      if (!response.ok) {
        console.error('API Error:', {
          status: response.status,
          statusText: response.statusText,
          url: response.url
        });
        throw new Error(`Failed to fetch price history (${response.status})`);
      }

      const data = await response.json();
      console.log('Raw API response:', data);
      console.log('API response details:', {
        hasAnalysis: !!data?.analysis,
        analysisLength: data?.analysis?.length || 0,
        timeRange: data?.timeRange,
        metadata: data?.metadata
      });

      if (!data?.analysis?.length) {
        console.log('No analysis data available', {
          data,
          responseType: typeof data,
          hasAnalysis: !!data?.analysis,
          analysisLength: data?.analysis?.length || 0
        });
        return {
          history: [],
          metadata: data.metadata || { platforms: [], websites: [] }
        };
      }

      // Transform the data according to the chart's expected format
      const analysis = data.analysis[0];
      console.log('First analysis item:', {
        platform: analysis._id?.platform,
        fifaCredits: analysis._id?.fifaCredits,
        dailyPricesCount: analysis.dailyPrices?.length || 0
      });

      const history = analysis.dailyPrices.map(day => ({
        date: day.date,
        lowestPrice: {
          amount: day.bestDeal.price,
          source: day.bestDeal.website,
          allSources: day.websites.map(web => ({
            source: web.name,
            price: web.avgPrice
          }))
        }
      }));

      console.log('Transformed history:', {
        historyLength: history.length,
        firstItem: history[0],
        lastItem: history[history.length - 1]
      });

      // Sort the history by date in ascending order
      const sortedHistory = history.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });

      // Calculate price analysis
      const allPrices = analysis.dailyPrices.flatMap(day => 
        day.websites.map(web => web.avgPrice)
      );

      const priceAnalysis = {
        lowestPriceEver: Math.min(...allPrices),
        highestPriceEver: Math.max(...allPrices)
      };

      const result = {
        history: sortedHistory,
        metadata: data.metadata,
        timeRange: {
          start: startDate,
          end: endDate
        },
        priceAnalysis
      };

      console.log('Final result:', {
        historyLength: result.history.length,
        timeRange: result.timeRange,
        hasAnalysis: !!result.priceAnalysis,
        metadata: result.metadata
      });

      return result;

    } catch (error) {
      console.error('Error in getPriceHistory:', error);
      return {
        history: [],
        metadata: { platforms: [], websites: [] },
        error: error.message
      };
    }
  }
};
